@import url('https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hubballi&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Custom Scrollbar for Webkit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 12px;
    /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the track (the empty space) */
    border-radius: 10px;
    /* Rounded corners of the track */
}

::-webkit-scrollbar-thumb {
    background-color: #698e9c;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners of the thumb */
    border: 3px solid #f1f1f1;
    /* Padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #5a7988;
    /* Darker color when hovering over the thumb */
}


/* For Firefox */
html,
.question-container,
textarea {
    scrollbar-width: thin;
    /* Makes the scrollbar thinner */
    scrollbar-color: #698e9c #f1f1f1;
    /* Thumb color, Track color */
}

/* Optional styling for Firefox scrollbar hover (not natively supported, but can be done using this approach) */
body,
.question-container,
textarea {
    scrollbar-gutter: stable both-edges;
    /* Stabilizes the scroll bar so it doesn't resize on hover */
}


body {
    font-family: 'Hubballi', cursive;
}

.main-nav {
    @media (max-width: 950px) {
        display: none;
    }
}

/*main {
    display: none !important;
}*/

nav {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
        display: flex;

        li {
            display: flex;
            align-items: center;

            a,
            span {
                display: flex;
                text-transform: uppercase;
                padding: 0.25rem 0.75rem;
                transition: all 250ms ease-in-out;
                color: white;
                cursor: pointer;

                &:hover {
                    background-color: white;
                    color: black;
                }
            }
        }
    }
}


ul {
    list-style: none;
}

h1,
h2,
h3,
h4 {
    font-weight: normal;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        .page {
            padding: 2rem;
            min-height: calc(100vh - 184px - 101.5px);

            .page-content {
                display: flex;
                flex-direction: column;
                max-width: 1440px;
                margin: 0 auto;
            }

            @media (max-width: 500px) {
                padding: 1rem 0.5rem;
            }

        }
    }

    .awards-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        background-color: #f5f5f5;

        .page-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            padding: 3rem;
            border-radius: 12px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            max-width: 600px;
            text-align: center;

            h2 {
                font-size: 2.5rem;
                color: #333;
                margin-bottom: 1rem;
                text-transform: uppercase;
            }

            p {
                font-size: 1.5rem;
                color: #777;
                margin: 0;
            }
        }
    }

    .about-page {
        background: url('../img/about/bg_opac_10.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;

        padding: 4rem 2rem;

        .content {
            max-width: 850px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);



            .wrapper {
                background: url('../img/about/bg_opac_10.jpg');
                background-repeat: no-repeat;
                background-position: bottom;
                background-position-y: 165px;
                background-size: cover;
                width: 100%;
                padding: 2rem;

                @media(max-width: 500px) {
                    padding: 2rem 0.5rem 0.5rem;
                }
            }

            .about-header {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 3rem;
                text-align: center;

                img {
                    width: 250px;
                    /* Reduced width for a more proportional look */
                    height: auto;
                    border-radius: 50%;
                    margin-bottom: 1.5rem;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                    /* Soft shadow for subtle focus */
                }

                h1 {
                    padding-left: 0;
                    color: #505a6b;

                }

                @media(max-width: 500px) {
                    margin-bottom: 2rem;
                }
            }

            .about-details {
                max-width: 750px;
                text-align: left;
                line-height: 1.75;
                font-size: 1.25rem;

                p {
                    margin-bottom: 1.5rem;
                }

                .cta-button {
                    display: inline-block;
                    margin-top: 2rem;
                    padding: 0.75rem 1.5rem;
                    background-color: #50627E;
                    /* Soft muted tone for a modern look */
                    color: white;
                    border-radius: 5px;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-weight: bold;
                    transition: background-color 0.3s ease, transform 0.3s ease;

                    &:hover {
                        background-color: #3b4b61;
                        transform: translateY(-3px);
                    }
                }
            }
        }
    }

    .contact-page {
        background: linear-gradient(135deg, #ffffff 50%, #698e9c 50%);

        .page-content {
            display: flex;
            flex-direction: row !important;
            justify-content: center;

            .contact-box {
                max-width: 500px;
                width: 100%;
                background: url('../img/contact/camera.jpg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

                .contact-box-content {
                    background-color: rgba(0, 0, 0, 0.4);
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .detail {
                        color: white;
                        display: flex;
                        align-items: center;
                        padding-left: 1.5rem;
                        margin-bottom: 1rem;
                        font-size: 1.25rem;

                        .icon {
                            margin-right: 1rem;
                            min-width: 1rem;
                            min-height: 1rem;
                        }

                        a {
                            color: white;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                @media (max-width: 1000px) {
                    display: none;
                }
            }

            .form-box {
                flex-direction: row;
                max-width: 500px;
                width: 100%;
                padding: 2rem;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                background-color: white;
                background: linear-gradient(135deg, #ffffff 50%, #698e9c 50%);

                h1 {
                    color: rgba(80, 114, 126, 1)
                }

                p {
                    margin: 2rem 0;

                    @media(max-width: 500px) {
                        margin: 1.5rem 0;
                    }
                }

                form {
                    display: flex;
                    flex-direction: column;

                    label {
                        margin-bottom: 0.5rem;
                    }

                    input,
                    textarea {
                        margin-bottom: 1rem;
                        padding: 0.5rem;
                        border: 1px solid rgba(0, 0, 0, 0.2);


                        &:focus {
                            outline: 1px solid rgba(0, 0, 0, 0.5) !important;
                        }
                    }

                    textarea {
                        resize: none;
                        min-height: 5rem;
                    }

                    button {
                        color: white;
                        background-color: rgba(80, 114, 126, 0.8);
                        padding: 0.5rem 1rem;
                        border: none;
                        cursor: pointer;
                        text-transform: uppercase;
                        transition: all 250ms ease-in-out;
                        align-self: center;

                        &:hover {
                            background-color: rgba(80, 114, 126, 1);
                        }
                    }
                }

                @media(max-width: 500px) {
                    padding: 1rem 0.5rem;
                }
            }
        }
    }

    .privacy-policy-page {
        .content {
            max-width: 850px;
            margin: 0 auto;
        }
    }

    .faq-page {
        background: linear-gradient(135deg, #ffffff 50%, #698e9c 50%);

        .content {
            display: flex;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            margin: 0 auto;
            max-width: 1440px;
            height: 700px;
            overflow: hidden;

            .media-container {
                background: url("../img/faq/media-bg.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 100%;

                @media (max-width: 1000px) {
                    display: none;
                }
            }

            .question-container {
                padding: 2rem;
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                background-color: white;

                h1 {
                    color: #4A90E2;
                    text-align: center;
                }

                h2 {
                    font-weight: bold;
                }

                section {
                    padding-bottom: 0.5rem;
                    margin-bottom: 1rem;

                    &:last-child {
                        border-bottom: none;
                    }

                    .question-wrap {
                        overflow: hidden;

                        h3 {
                            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                            position: relative;
                            z-index: 100;
                            background-color: white;
                            font-weight: bold;

                            .icon {
                                width: 1rem;
                                min-width: 1rem;
                            }

                            &:hover {
                                color: #4A90E2;
                            }
                        }

                        .active-h3 {
                            color: #4A90E2;
                        }

                        p {
                            position: relative;
                            transition: all 250ms ease-in-out;
                            margin-bottom: 0;
                        }

                    }
                }

                @media (max-width: 500px) {
                    padding: 1rem 0.25rem;
                }
            }
        }


        .contact-help-box {
            background-color: #f7f7f7;
            padding: 2rem;
            border-radius: 0.5rem;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            margin: 2rem auto 0;
            transition: all 0.3s ease-in-out;
            max-width: 1440px;

            h2 {
                font-size: 1.75rem;
                margin-bottom: 1rem;
                color: #4a90e2;
            }

            p {
                font-size: 1.1rem;
                color: #333;
                margin-bottom: 1.5rem;
            }

            .contact-details {
                display: flex;
                justify-content: center;
                gap: 1.5rem;

                .detail {
                    display: flex;
                    align-items: center;
                    gap: 0.75rem;
                    font-size: 1rem;

                    .icon {
                        font-size: 1.5rem;
                        color: #4a90e2;
                        transition: color 0.3s;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: #333;
                        transition: color 0.3s ease;
                        font-weight: bold;
                        gap: 0.5rem;

                        &:hover {
                            color: #4a90e2;
                            text-decoration: underline;
                        }

                        .mobile-icon {
                            transform: scale(0.8);
                        }

                        .email-icon {
                            transform: scale(0.9);
                        }

                        span {
                            @media (max-width: 1000px) {
                                display: none;
                            }
                        }
                    }

                    &:hover {
                        .icon {
                            color: #698e9c;
                        }
                    }
                }
            }

            @media (max-width: 500px) {
                margin-top: 1rem;
                padding: 1rem;
            }
        }
    }


    .document {
        h1 {
            margin-bottom: 2rem;
        }

        h2 {
            color: rgb(80, 114, 126);
            margin-bottom: 0.5rem;
            margin-top: 1rem;
        }

        h3 {
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: all 250ms ease-in-out;
            padding: 0.5rem;
        }

        p {
            padding: 0.5rem;
            line-height: 1.15;

            a {
                color: rgb(80, 114, 126);
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        ul {
            margin: 1rem 0 0 1.5rem;
            list-style-type: square;

            li {
                line-height: 1.25;
            }
        }
    }

    .reviews-page {
        .content {
            max-width: 1440px;
            margin: 0 auto;
            text-align: center;

            h1 {
                font-size: 2.2rem;
                margin-bottom: 3rem;
                color: #333;

                @media(max-width: 500px) {
                    margin-top: 1rem;
                }
            }

            .review-grid {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 2rem;
                margin: 0 auto;

                .review-card {
                    background-color: #002f4b;
                    color: white;
                    border-radius: 0;
                    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
                    overflow: hidden;
                    text-align: center;
                    max-width: 600px;
                    transition: all 0.3s ease, box-shadow 0.3s ease;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 2rem;
                    cursor: pointer;
                    position: relative;

                    &:nth-child(2n) {
                        background-color: #9e3c3c;
                    }

                    &:nth-child(3n) {
                        background-color: #698e9c;
                    }


                    &:hover {
                        transform: translateY(-10px);
                        background-color: #004d73;
                    }

                    h3 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        margin-bottom: 0.5rem;
                    }

                    blockquote {
                        font-size: 1.15rem;
                        line-height: 1.5;
                        margin: 1rem 0;
                        padding: 0 1rem;
                    }

                    .profile-section {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 1rem;

                        .profile-picture {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            background-color: gray;
                            margin-right: 1rem;
                        }

                        .profile-info {
                            h3 {
                                margin: 0;
                            }

                            .position {
                                font-size: 0.9rem;
                                color: #f4d03f;
                            }
                        }
                    }

                    .stars {
                        color: #f4d03f;
                        font-size: 1rem;
                    }

                    .learn-more-btn {
                        background-color: #f03a47;
                        color: white;
                        border: none;
                        border-radius: 20px;
                        padding: 10px 20px;
                        font-size: 1rem;
                        margin-top: 1rem;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: #e63946;
                        }
                    }
                }
            }
        }
    }
}



.counters {
    display: flex;
    justify-content: space-around;
    text-align: center;
    background: linear-gradient(120deg, #d4d4d4, #ffffff);
    padding: 5rem 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease-in-out;
    margin-bottom: 6rem;

    .counter-item {
        flex: 1;
        transition: transform 0.3s ease-in-out;

        .icon {
            font-size: 3.5rem;
            color: #BFA16B;
            transition: color 0.3s ease;
        }

        h3 {
            font-size: 2.5rem;
            margin: 15px 0;
            color: #333;
            font-weight: 600;
            letter-spacing: 1px;
        }

        p {
            font-size: 1.4rem;
            color: #7c7c7c;
            margin: 0;
            font-weight: 400;
        }
    }
}

$card-bg-color: #7c9282;
$card-text-color: white;
$button-bg-color: white;
$button-hover-bg-color: #e6e6e6;
$button-text-color: $card-bg-color;

.container {
    background-color: #f7f7f7;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    display: flex;


    .package-container {
        max-width: 1440px;
        margin: 0 auto;
        display: grid;
        gap: 1.5rem;
        padding: 1.5rem;

        // Default layout: 2x3 (2 columns, 3 rows)
        grid-template-columns: repeat(3, 1fr);

        // Media query for larger screens: 3x2 (3 columns, 2 rows)
        @media (max-width: 1300px) {
            grid-template-columns: repeat(2, 1fr);
        }

        // Media query for smaller screens: 1x6 (1 column, 6 rows)
        @media (max-width: 700px) {
            grid-template-columns: 1fr;
        }
    }

    .package-card {
        display: flex;
        flex-direction: column;
        background-color: rgb(80, 114, 126);
        color: $card-text-color;
        padding: 1.5rem;
        width: 100%;
        height: 100%;
        max-width: 400px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;

        h4 {
            font-size: 2rem;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 40%;
                height: 1px;
                background-color: #ccc;
                margin-top: 0.5rem;
                margin: 2rem auto;
            }
        }

        .price {
            font-weight: bold;
            margin-bottom: 2rem;
        }

        .description {
            margin-top: auto;
            margin-bottom: 2rem;
        }

        button {
            align-self: center;
            background-color: transparent;
            border: 2px solid white;
            color: white;
            padding: 0.75rem 1.25rem;
            font-size: 1rem;
            cursor: pointer;
            border-radius: 3px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: rgb(105, 142, 156);
            }
        }

    }
}

.gallery-page {
    background: url('../img//gallery/bg.jpg');
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .share-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        background-color: #0073e6;
        color: white;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        z-index: 1000;
    }

    .share-button:hover {
        background-color: #005bb5;
        transform: scale(1.1);
    }

    .share-button .icon {
        font-size: 1.5rem;
    }


    .content {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 2rem;

        .image-grid {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            justify-items: center;
            align-items: center;

            .image-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                width: 100%;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 0.2rem;
                    //border: 2px solid rgb(203, 170, 170);
                    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);

                }

                .gallery-name {
                    position: absolute;
                    bottom: 1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    font-weight: bold;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.6);
                    color: white;
                    padding: 0.25rem 0.5rem;
                    border-radius: 0.25rem;
                }
            }

            @media (max-width: 900px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
            }

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(6, 1fr);
            }
        }
    }
}

.album-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f8f8f8;

    .album-header {
        width: 100%;
        padding: 1rem;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin-bottom: 2rem;

        h1 {
            font-size: 2.5rem;
            color: #333;
            margin: 0;
        }
    }

    .album-content {
        width: 100%;
        max-width: 1200px;
        padding: 0 1rem;

        .react-photo-album__masonry {
            column-gap: 1rem;
        }

        img {
            border-radius: 12px;
            transition: transform 0.3s ease-in-out;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            &:hover {
                transform: scale(1.05);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
            }
        }
    }

    .react-photo-album__image-wrapper {
        margin-bottom: 1rem;
    }

    .lightbox {
        .react-modal {
            backdrop-filter: blur(8px);
        }
    }
}