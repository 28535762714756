@charset "UTF-8";
.main-slider {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto;
  background-color: black;
}
.main-slider .slick-slider {
  max-width: 1440px;
  margin: 0 auto;
}
.main-slider .slick-prev,
.main-slider .slick-next {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  padding: 5px;
  color: rgb(178, 178, 178);
  margin: 0 2rem;
  border: 2px solid transparent;
}
.main-slider .slick-prev:hover,
.main-slider .slick-next:hover {
  background-color: black;
  color: white;
}
.main-slider .slick-dots {
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 15px;
}
.main-slider .slick-dots li {
  margin: 0 5px;
  /* Non-active dot style */
  /* Active dot style */
}
.main-slider .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(50, 50, 50);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.main-slider .slick-dots li button:before {
  content: none;
  /* Remove the default pseudo-element */
}
.main-slider .slick-dots li button:hover {
  background: rgba(255, 255, 255, 0.8);
}
.main-slider .slick-dots li.slick-active button {
  background: white;
  transform: scale(1.15);
  /* Enlarged active dot */
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
}
.main-slider img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1024px) {
  .main-slider img {
    height: 500px;
  }
}
@media only screen and (max-width: 768px) {
  .main-slider img {
    height: 400px;
  }
  .slick-prev,
  .slick-next {
    font-size: 16px;
    height: 30px;
    width: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .main-slider img {
    height: 300px;
  }
  .slick-prev,
  .slick-next {
    height: 25px;
    width: 25px;
    font-size: 14px;
  }
}
/* ReviewSlider.css */
.review-slider {
  background: url("../img/home/paralax1.jpg") no-repeat top right;
  background-size: cover;
  background-attachment: fixed;
  padding: 5rem 0;
  padding: 5rem;
  display: flex;
  display: none;
  /* Arrow custom styles */
  /* Dots custom styles */
  /* Responsive Styles */
}
.review-slider .slider-container {
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgba(255, 0, 0, 0.7);
}
.review-slider .testimonial-card {
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.review-slider .testimonial-card blockquote {
  font-style: italic;
  color: white;
  margin-bottom: 10px;
  font-size: 1.25rem;
  quotes: "“ " " ”" "‘" "’";
  text-shadow: 0px 0px 10px black;
}
.review-slider .testimonial-card blockquote::before {
  content: open-quote;
  font-size: 2rem;
}
.review-slider .testimonial-card blockquote::after {
  content: close-quote;
  font-size: 2rem;
}
.review-slider .testimonial-card h3 {
  font-size: 1.35rem;
  margin-top: 15px;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 3px black;
}
.review-slider .slick-prev,
.review-slider .slick-next {
  font-size: 1.5rem;
  color: #333;
  border: none;
  background: none;
  display: none !important;
}
.review-slider .slick-prev:hover,
.review-slider .slick-next:hover {
  color: #000;
}
.review-slider .slick-dots {
  display: flex;
  justify-content: center;
  width: 100%;
}
.review-slider .slick-dots li {
  margin: 0 5px;
  /* Non-active dot style */
  /* Active dot style */
}
.review-slider .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(50, 50, 50);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.review-slider .slick-dots li button:before {
  content: none;
  /* Remove the default pseudo-element */
}
.review-slider .slick-dots li button:hover {
  background: rgba(255, 255, 255, 0.8);
}
.review-slider .slick-dots li.slick-active button {
  background: white;
  transform: scale(1.15);
  /* Enlarged active dot */
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
}
@media (max-width: 768px) {
  .review-slider .testimonial-card {
    padding: 15px;
  }
  .review-slider .testimonial-card h3 {
    font-size: 1rem;
  }
  .review-slider .slick-prev,
  .review-slider .slick-next {
    font-size: 1.2rem;
  }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  max-width: 1000px;
  margin: 20px auto;
}

.gallery-image {
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}/*# sourceMappingURL=Sliders.css.map */