header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: black;
    color: white;
    //margin-bottom: -345px;
    position: relative;
    z-index: 1001;

    #logo {
        width: 200px;
    }

    .icon {
        cursor: pointer;
        height: 2rem;
        width: 2rem;

        @media(min-width: 950px) {
            display: none;
        }
    }

    .icon-off {
        display: none;
    }
}


footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0 0;
    padding: 2rem;
    background-color: black;
    color: white;

    h3 {
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1.75rem;

        @media (max-width: 768px) {
            display: none;
            margin-bottom: 1rem;
        }
    }

    #footer-info {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 800px;

        .details {
            display: flex;
            flex-direction: column;
            align-items: center;

            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;

                li {
                    text-align: center;
                    margin-top: 0.5rem;
                }
            }
        }

        #first-details {
            align-items: flex-start;
            padding-top: 3rem;
            width: 30%;

            nav {
                ul {
                    align-items: flex-start;

                    li {
                        text-align: left;
                        margin-top: 0.2rem;

                        a {
                            text-align: center;
                        }

                        @media (max-width: 768px) {
                            margin-top: 0;
                            justify-content: center;
                            align-self: center;
                            text-align: center;
                        }
                    }

                    @media (max-width: 768px) {
                        flex-direction: row;
                        width: 100%;
                        justify-content: center;
                    }
                }

                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            @media (max-width: 768px) {
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }

        #second-details {
            width: 40%;

            ul {
                width: 100%;
                border-left: 1px solid rgba(128, 128, 128, 0.5);
                border-right: 1px solid rgba(128, 128, 128, 0.5);
            }

            @media (max-width: 768px) {
                display: none;
            }
        }

        #third-details {
            padding-top: 3rem;
            align-items: flex-end;
            width: 30%;

            ul {
                align-items: flex-end;

                li {
                    margin-top: 0.2rem;
                    text-align: right;
                }
            }

            @media (max-width: 768px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            max-width: 400px;
        }
    }

    .social-nav {
        margin: 2rem 0;

        @media (max-width: 768px) {
            margin: 1rem 0;
        }
    }

    .copyright {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            text-align: center;
        }

        a {
            margin-top: 0.5rem;
            color: rgb(80, 114, 126);
            transition: all 250ms ease-in-out;
            text-align: center;

            &:hover {
                color: white;
            }
        }
    }

    @media (max-width: 500px) {
        padding: 1rem;
    }
}