header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: black;
  color: white;
  position: relative;
  z-index: 1001;
}
header #logo {
  width: 200px;
}
header .icon {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}
@media (min-width: 950px) {
  header .icon {
    display: none;
  }
}
header .icon-off {
  display: none;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0 0;
  padding: 2rem;
  background-color: black;
  color: white;
}
footer h3 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.75rem;
}
@media (max-width: 768px) {
  footer h3 {
    display: none;
    margin-bottom: 1rem;
  }
}
footer #footer-info {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}
footer #footer-info .details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
footer #footer-info .details ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
footer #footer-info .details ul li {
  text-align: center;
  margin-top: 0.5rem;
}
footer #footer-info #first-details {
  align-items: flex-start;
  padding-top: 3rem;
  width: 30%;
}
footer #footer-info #first-details nav ul {
  align-items: flex-start;
}
footer #footer-info #first-details nav ul li {
  text-align: left;
  margin-top: 0.2rem;
}
footer #footer-info #first-details nav ul li a {
  text-align: center;
}
@media (max-width: 768px) {
  footer #footer-info #first-details nav ul li {
    margin-top: 0;
    justify-content: center;
    align-self: center;
    text-align: center;
  }
}
@media (max-width: 768px) {
  footer #footer-info #first-details nav ul {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  footer #footer-info #first-details nav {
    width: 100%;
  }
}
@media (max-width: 768px) {
  footer #footer-info #first-details {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
footer #footer-info #second-details {
  width: 40%;
}
footer #footer-info #second-details ul {
  width: 100%;
  border-left: 1px solid rgba(128, 128, 128, 0.5);
  border-right: 1px solid rgba(128, 128, 128, 0.5);
}
@media (max-width: 768px) {
  footer #footer-info #second-details {
    display: none;
  }
}
footer #footer-info #third-details {
  padding-top: 3rem;
  align-items: flex-end;
  width: 30%;
}
footer #footer-info #third-details ul {
  align-items: flex-end;
}
footer #footer-info #third-details ul li {
  margin-top: 0.2rem;
  text-align: right;
}
@media (max-width: 768px) {
  footer #footer-info #third-details {
    display: none;
  }
}
@media (max-width: 768px) {
  footer #footer-info {
    max-width: 400px;
  }
}
footer .social-nav {
  margin: 2rem 0;
}
@media (max-width: 768px) {
  footer .social-nav {
    margin: 1rem 0;
  }
}
footer .copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
}
footer .copyright span {
  text-align: center;
}
footer .copyright a {
  margin-top: 0.5rem;
  color: rgb(80, 114, 126);
  transition: all 250ms ease-in-out;
  text-align: center;
}
footer .copyright a:hover {
  color: white;
}
@media (max-width: 500px) {
  footer {
    padding: 1rem;
  }
}/*# sourceMappingURL=Styles.css.map */