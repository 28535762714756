.banner {
  background-color: red;
  position: sticky;
  top: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 1.15rem;
}/*# sourceMappingURL=Banner.css.map */