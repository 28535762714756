.home-page {
    padding: 0 !important;

    .content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;

        h1 {
            font-size: 3rem;
            text-align: center;
            width: 100%;
            padding: 4rem 1rem;
        }

        .slogan {
            background-color: black;
            color: white;
            text-align: center;
            font-size: 3rem;
            padding: 4rem 0;
            letter-spacing: 2px;

            span {
                display: inline-block;
                width: 100%;
                padding: 0 1rem;
                font-family: 'Dancing Script', cursive;
                max-width: 1440px;
            }
        }

        .info {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 2rem 1rem;
            font-size: 1.8rem;
            text-align: center;
            letter-spacing: 1px;
            max-width: 1440px;
            margin: 0 auto;


            .icon {
                margin-right: 1rem;
                color: #BFA16B;
            }
        }

        .more-margin-top {
            margin-top: 2rem;
        }

        .more-margin-bottom {
            margin-top: 2rem;
        }

    }

    .category-container {
        background-color: #f4f4f4;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        margin: 12rem 0;

        .category-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 1440px;
            height: 30rem;
            padding: 2rem;
            margin: 0 auto;

            .category-photo-box {
                position: relative;
                left: 5%;
                top: -8rem;
                min-width: 60%;
                height: 80%;
                z-index: 1;
                overflow: hidden;
                border-radius: 10px;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

                div {
                    display: flex;
                    justify-content: flex-end;
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    background-position: center;

                    .icon {
                        color: white;
                        width: 1.5rem;
                        height: 1.5rem;
                        margin: 0.75rem;
                        transition: transform 300ms ease;

                        &:hover {
                            transform: scale(1.25);
                        }

                    }
                }

                @media(max-width: 700px) {
                    left: 0;
                    top: -5rem;
                    min-width: 90%;
                    height: 70%;
                }
            }

            .category-description-box {
                position: relative;
                right: 5%;
                bottom: -8rem;
                z-index: 2;
                display: flex;
                flex-direction: column;
                padding: 1.5rem;
                line-height: 1.5;
                background-color: rgba(255, 255, 255, 0.85);
                border: 2px solid rgba(80, 114, 126, 0.1);
                border-radius: 10px;
                order: 2;
                color: rgb(80, 114, 126);
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

                h3 {
                    font-size: 2.2rem;
                    text-transform: capitalize;
                    margin-bottom: 1rem;
                }

                p {
                    margin-top: 1rem;
                    font-size: 1.2rem;
                    color: #555;
                }

                .long {
                    display: unset;

                    @media(max-width: 1000px) {
                        display: none;
                    }
                }

                .short {
                    display: none;

                    @media(max-width: 1000px) {
                        display: unset;
                    }
                }

                @media(max-width: 1000px) {
                    bottom: -5rem;
                }

                @media(max-width: 700px) {
                    right: 0;
                    margin-left: -70%;
                    bottom: -6rem;
                }

            }

            @media(max-width: 700px) {
                padding: 0.5rem;
            }
        }



        .flipped {
            .category-photo-box {
                order: 2;
                left: auto;
                right: 5%;

                @media(max-width: 700px) {
                    right: 0;
                    top: -5rem;
                    min-width: 90%;
                    height: 70%;
                }
            }

            .category-description-box {
                order: 1;
                right: auto;
                left: 5%;
                margin-left: 0;


                @media(max-width: 700px) {
                    left: 0;
                    margin-right: -70%;
                    bottom: -6rem;
                }
            }
        }

        @media(max-width: 700px) {
            margin: 4rem 0;
        }
    }

    .maternity {
        background-image: url('../img/home/maternity.jpg');
        background-position: center;
        background-size: cover;
    }

    .newborn {
        background-image: url('../img/home/newborn.jpg');
        background-position: top;
        background-size: cover;
    }

    .sitter {
        background-image: url('../img/home/sitter.jpg');
        background-position: center;
        background-size: cover;
    }

    .family {
        background-image: url('../img/home/family.jpg');
        background-position: top;
        background-size: cover;
    }

    .cake {
        background-image: url('../img/home/cakesmash.jpg');
        background-position: center;
        background-size: cover;
    }

    .engagement {
        background-image: url('../img/home/engagement.jpg');
        background-position: center;
        background-size: cover;
    }
}