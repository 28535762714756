.mobile-nav {
  display: flex;
  flex-direction: column;
  background-color: black;
  z-index: 1000;
  position: relative;
  padding: 2rem;
  transition: all 200ms ease-in-out;
  margin-top: -345px;
  margin-bottom: 0;
}
.mobile-nav ul {
  flex-direction: column;
  align-items: center;
}
.mobile-nav .secondary-nav {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.mobile-nav .secondary-nav ul {
  flex-direction: row;
}
.mobile-nav .social-nav {
  margin-top: 1rem;
}
.mobile-nav .social-nav ul {
  flex-direction: row;
}
.mobile-nav .social-nav ul li a .icon {
  width: 1rem;
  height: 1rem;
}

.mobile-nav-open {
  margin-top: 0;
  margin-bottom: -345px;
}

.scroll-up {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 2.2rem;
  height: 2.2rem;
  bottom: 8rem;
  right: 2rem;
  border-radius: 50%;
  background-color: #50727e;
  transition: all 350ms ease-in-out;
  cursor: pointer;
  z-index: 1002;
  box-shadow: 0 0 5px white;
}
.scroll-up .icon {
  color: white;
}
.scroll-up:hover {
  background-color: darkred;
}/*# sourceMappingURL=Navigations.css.map */